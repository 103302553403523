import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import BlogRoll from '../components/BlogRoll';
import { ArrowLeftIcon, ArrowRightIcon } from '../components/Icon';
import Section from '../components/Section';

function EventRollPaginated({ items, pageSize = 6 }) {
  const [currentPage, setCurrentPage] = useState(1);

  const numPages = Math.ceil(items.length / pageSize);

  const itemsOnPage = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return items.slice(firstPageIndex, lastPageIndex);
  }, [items, currentPage, pageSize]);

  function prevPage() {
    setCurrentPage(currentPage - 1);
  }

  function nextPage() {
    setCurrentPage(currentPage + 1);
  }

  function toPage(n) {
    setCurrentPage(n);
  }

  return (
    <>
      <Section overlaySide="left">
        <div className="container">
          <BlogRoll blogPosts={itemsOnPage} isVerticalLayout />
          <div className="text-center">
            <div className="inline-block m-auto">
              {currentPage !== 1 && (
                <span
                  role="menuitem"
                  tabIndex={0}
                  onKeyPress={prevPage}
                  onClick={prevPage}
                  className="mr-8 py-1 px-1 font-medium cursor-pointer hover:text-primaryHover focus:text-primaryPressed"
                >
                  <ArrowLeftIcon
                    className={'inline-block h-4 w-4 ml-2'}
                    strokeWidth="3"
                  />{' '}
                  Prev
                </span>
              )}
              {Array.from({ length: numPages }, (_, i) => {
                if (numPages === 1) {
                  return <></>;
                }
                if (i + 1 === currentPage) {
                  return (
                    <span
                      key={`pagination-number${i + 1}`}
                      className="mx-1 py-1 px-2 font-medium border-2 border-black rounded-full"
                    >
                      {i + 1}
                    </span>
                  );
                } else {
                  return (
                    <span
                      key={`pagination-number${i + 1}`}
                      role="menuitem"
                      tabIndex={0}
                      onKeyPress={() => toPage(i + 1)}
                      onClick={() => toPage(i + 1)}
                      className="mx-3 py-1 px-1 font-medium cursor-pointer hover:text-primaryHover focus:text-primaryPressed"
                    >
                      {i + 1}
                    </span>
                  );
                }
              })}
              {currentPage !== numPages && numPages > 1 && (
                <span
                  role="menuitem"
                  tabIndex={0}
                  onKeyPress={nextPage}
                  onClick={nextPage}
                  className="ml-8 py-1 px-1 font-medium cursor-pointer hover:text-primaryHover focus:text-primaryPressed"
                >
                  Next{' '}
                  <ArrowRightIcon
                    className={'inline-block h-4 w-4'}
                    strokeWidth="3"
                  />
                </span>
              )}
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}

EventRollPaginated.propTypes = {
  items: PropTypes.array.isRequired,
  templateFilters: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ),
  pageSize: PropTypes.number,
};

export default EventRollPaginated;
