import * as React from 'react';
import PropTypes from 'prop-types';

import Hero from '../components/Hero';
import Section from '../components/Section';
import { MarkdownContent } from '../components/Content';

const EventsPage = ({ title, heroImage, heroContent }) => {
  return (
    <>
      <Hero image={heroImage} />
      <Section colorScheme="black">
        <div className="container flex flex-col md:flex-row py-12 gap-x-12">
          <div className="pb-4 md:w-1/3">
            <h2>
              <span className="bg-theme-underline">{title}</span>
            </h2>
          </div>
          <div className="md:w-2/3 text-base">
            {heroContent && <MarkdownContent content={heroContent} />}
          </div>
        </div>
      </Section>
    </>
  );
};

EventsPage.propTypes = {
  title: PropTypes.string.isRequired,
  heroImage: PropTypes.object.isRequired,
  heroContent: PropTypes.string.isRequired,
};

export default EventsPage;
