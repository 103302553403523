import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { defaultLocale } from '../config/i18n.config';
import { LocaleProvider } from '../context/localeContext';
import EventRollPaginated from '../components/EventRollPaginated';
import Layout from '../components/Layout';
import Section from '../components/Section';
import EventsPage from './EventsPage';
import NewsletterSignupForm from '../components/NewsletterSignupForm';

const EventsPageTemplate = ({ data, pageContext }) => {
  const title =
    data.eventsPage.frontmatter[pageContext.locale].title ||
    data.eventsPage.frontmatter[defaultLocale].title;
  const heroImage = data.eventsPage.frontmatter[defaultLocale].image;
  const heroContent =
    data.eventsPage.frontmatter[pageContext.locale].hero ||
    data.eventsPage.frontmatter[defaultLocale].hero;
  const events = data.events.edges;

  return (
    <LocaleProvider locale={pageContext.locale}>
      <Layout>
        <EventsPage
          title={title}
          heroImage={heroImage}
          heroContent={heroContent}
        />
        <EventRollPaginated items={events} />
        <Section>
          <NewsletterSignupForm />
        </Section>
      </Layout>
    </LocaleProvider>
  );
};

EventsPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default EventsPageTemplate;

export const eventsPageQuery = graphql`
  query EventsPage($id: String!, $locale: String!) {
    eventsPage: markdownRemark(id: { eq: $id }) {
      frontmatter {
        en {
          title
          hero
          image {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH)
            }
          }
        }
        de {
          title
          hero
        }
      }
    }
    events: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fields: { collection: { in: ["events"] }, locale: { eq: $locale } }
        frontmatter: { title: { ne: "" } }
        rawMarkdownBody: { ne: "" }
      }
    ) {
      edges {
        node {
          ...BlogRollItem
        }
      }
    }
  }
`;
